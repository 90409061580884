$primary-font-color:            rgb(167, 166, 166);
$primary-font-color-lighter:    lightgray;
$primary-font-color-darker:     rgb(83, 77, 77);
$primary-font-color-mild:       gray;
$bg-color-mobile:               rgb(66, 0, 221);
$nav-bg-color-mobile:           rgba(0, 0, 0, 0.7);
$nav-bg-color:                  rgba(0, 0, 0, 0.4);
$nav-shadow-color:              rgba(0, 0, 0, 0.2);
$mobile-screen-size:              576px;
$invisible-color:               rgba(0, 0, 0, 0);


@mixin main-effect($degree) {
    filter: brightness($degree);
}

@mixin common-transition($duration) {
    transition-duration: $duration !important;
    transition-delay: 0.1s;
    transition-property: all;
}

%common-nav-links {
    color: $primary-font-color;
    outline: none;
    margin-right: 3%;
    border-radius: 10%;
}

%common-nav-bar {
    background: $nav-bg-color;
    font-stretch: ultra-expanded;
}

%rotate-effect {
    transform: rotate(40deg);
}


.external {
    @include common-transition(0.2s);

    &:hover {
        @extend %rotate-effect;
        color: $primary-font-color-lighter !important;
    }

    &.active {
        color: $primary-font-color !important;
    }
}

.nav-link {
    @extend %common-nav-links;

    &:hover, &.active:not(.external) {
        @include main-effect(1.8);
    }
}

.brand {
    @extend %common-nav-links;
    @include common-transition(0.2s);
    max-width: 8%;

    img {
        @include common-transition(0.2s);
        &:hover { @extend %rotate-effect; }
    }

    &:hover, &:focus { @include main-effect(1.8); }
}

.top {
    @include common-transition(0.1s);

    &:hover {
        @include main-effect(1.8);
    }

    &:focus {
        outline: none;
    }

    opacity: 100;
    border: none;
    position: fixed;
    bottom: 6%;
    right: 1.5%;
    z-index: 99;
    box-shadow: none;
    border-color: $primary-font-color;
    outline: none;
    background-color: $nav-bg-color;
    color: $primary-font-color;
    padding: 0.5%;
    border-radius: 10px;
    box-shadow: 0 0 5px 2px $nav-shadow-color;
}


.navbar {
    @extend %common-nav-bar;
    box-shadow: 0 4px 4px 0px $nav-shadow-color;
}

.badge {
    width: 100%;
    background-color: $primary-font-color-darker;
}

.footer {
    @extend %common-nav-bar;
    cursor: default;
    width: 100%;
    position: fixed;
    bottom: 0;
    box-shadow: 0px -4px 4px 0px $nav-shadow-color;
    padding-top: 0.5%;
}

.card {
    @include common-transition(0.5s);
    background: $nav-shadow-color;
    border-color: $primary-font-color-mild !important;
    border-width: 3px;
    box-shadow: 0 0 5px 5px $nav-shadow-color;

}

.card-clickable {
    &:hover {
        @include common-transition(0.8s);
        @include main-effect(1.8);
        cursor: pointer;
        outline: none;
    }
}

.row {
    &:first-child {
        margin-top: 5%;
    }

    &:last-child {
        margin-bottom: 5%;
    }
}

i {
    color: $primary-font-color-lighter;
    font-size: 90%;
    font-stretch: ultra-condensed;
    font-family: monospace;
}


/* NOTE: if on small screens:
    - Darken the navbar background color 
    - Adjust padding for move back to top button
*/
@media screen and(max-width: $mobile-screen-size) {
    .navbar, .footer {
        background: $nav-bg-color-mobile;
    }

    .top {
        padding: 1.5%;
    }
}


// Add box shadow to navbar links if on large screens
@media screen and (min-width: $mobile-screen-size) {
    .nav-link.active:not(.external) {
        box-shadow: inset 0 0 6px $nav-bg-color;
    }
}


@keyframes Gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

body {
    width: 100wh;
    color: #fff;
    background: linear-gradient(-45deg, rgb(255, 255, 255), rgb(66, 0, 221), rgb(8, 0, 80), rgb(0, 0, 0));
    background-size: 400% 400%;
    -webkit-animation: Gradient 40s ease infinite;
    -moz-animation: Gradient 40s ease infinite;
    animation: Gradient 40s ease infinite;
}

.cursor {
    background: $primary-font-color-lighter;
    line-height: 17px;
    margin-left: 3px;
    animation: blink 0.8s infinite;
    width: 14px;
    height: 30px;
    display: inline-block;
}

@keyframes blink {
    0% {background: $invisible-color}
    50% {background: $primary-font-color-lighter}
    100% {background: $invisible-color}
}
  
.cursored {
    display: inline-block;
}

.intro-text {
    font-size: 115%;
}

.intro-nav-link {
    font-stretch: ultra-expanded;
    color: white;
    background-color: $primary-font-color-darker;
    border-radius: 8%;
    padding: 4px;
    font-size: 80%;

    &:hover {
        color: white;
        text-decoration: none;
        @include main-effect(1.8);
    }
}